<template>
  <div class="inside-os">
    <div class="com-title">
      <span>规则说明</span>
      <select @change="changeSel" v-model="roomeng">
        <option v-for="item in gameList" :value="item.roomeng" :key="item.Cato"> {{item.Gamename}} </option>

      </select>
    </div>

    <div class="rule-content">
      <h3>重要声明</h3>
      <p class="p"> 1. 客户一经在本公司开户或投注，即被视为已接受这些规则，所有投注都必须在开奖前时间内进行否则投注无效。 </p>
      <p class="p"> 2.如果客户怀疑自己的资料被盗用，应立即通知本公司，并更改详细数据，以前的使用者名称及密码将全部无效。 </p>
      <p class="p"> 3. 客户有责任确保自己的帐户及登入资料的保密性。在本网站上以个人的使用者名称及密码进行的任何网上投注将被视为有效。 </p>
      <p class="p">4. 为了避免出现争议，请务必在下注之後检查“ 下注状况 ”。</p>
      <p class="p">5. 一旦投注被接受，则不得取消或修改。</p>
      <p class="p">6. 所有投注都必须在开奖前时间内进行否则投注无效。</p>
      <p class="p"> 7. 公布赔率时出现的任何打字错误或非故意人为失误，本公司保留改正错误和按正确赔率结算投注的权力。您居住所在地的法律有可能规定网络博弈不合法；若此情况属实，本公司将不会批准您使用付账卡进行交易。 </p>
      <p class="p"> 8. 公布之所有赔率为浮动赔率，派彩时的赔率将以确认投注时之赔率为准。 </p>
      <p class="p"> 9. 如本公司察觉客户投注状况异常时，有权即时中止客户投注；在本公司中止下注前，客户之所有投注仍属有效，不得要求取消或延迟交收，以及不得有任何异议。 </p>
      <p class="p">10. 所有投注派彩彩金皆含本金。</p>
      <p class="p">11. 如因在本网站投注触犯当地法律，本公司概不负责。</p>
      <div class="text"></div>
      <!-- 游戏说明-->
      <div class="text" v-show="gameDes.description">
        <h3 class="h3" style="padding-left:20px">游戏说明</h3>
        <div class="rule">
          <div class="section_noindex blacktxt" v-html="gameDes.description">

          </div>
        </div>
      </div>

      <div class="text">
        <h3 class="h3" style="padding-left:20px">玩法规则</h3>
        <!-- 五星彩 -->
        <div class="rules" v-if="ruleTab=='wxc'">
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>五位数的每注彩票由00000-99999中的任意5位自然数排列而成。 本站取前面4位做为游戏规则！</span></li>

          </ul>
          <h3 class="wxc_h3">假设下列为开奖结果:</h3>
          <table class="wxcsuch_table" border style="width:50%">
            <thead>
              <tr class="xiazhukuang2">
                <th>仟</th>
                <th>佰</th>
                <th>拾</th>
                <th>个</th>
                <th>球5</th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>
                    <font color="#cc0000">1</font>
                  </b></td>
                <td><b>
                    <font color="#cc0000">2</font>
                  </b></td>
                <td><b>
                    <font color="#cc0000">3</font>
                  </b></td>
                <td><b>
                    <font color="#cc0000">4</font>
                  </b></td>
                <td><b>
                    <font color="#cc0000">5</font>
                  </b></td>

              </tr>
            </tbody>
          </table>
          <h3 class="wxc_h3 mar10">依照开奖结果，中奖范例如下</h3>
          <ul class="rule_ul">
            <li class="first "><span>一字定中奖：1XXXX；X2XXX；XX3XX；XXX4X；XXXX5</span></li>
          </ul>
          <ul class="rule_ul">
            <li class="first "><span>二字定中奖：12XX； 1X3X； 1XX4； X23X； X2X4； XX34</span></li>
          </ul>
          <ul class="rule_ul">
            <li class="first "><span>三字定中奖：123X； 12X4； 1X34； X234</span></li>
          </ul>
          <ul class="rule_ul">
            <li class="first "><span>四字定中奖：1234</span></li>
          </ul>
          <ul class="rule_ul">
            <li class="first "><span>五位二定中奖：1XXX5；X2XX5；XX3X5；XXX45；</span></li>
          </ul>
          <ul class="rule_ul">
            <li class="first "><span>二字现中奖：12；13；14；23；24；34</span></li>
          </ul>
          <ul class="rule_ul">
            <li class="first "><span>三字现中奖：123；124；134；234</span></li>
          </ul>
          <ul class="rule_ul">
            <li class="first "><span>四字现中奖：1234 现；</span></li>
          </ul>
          <!-- ------------- -->
        </div>
        <!-- 快乐8 -->
        <div class="rules" v-if="ruleTab=='kl8'">
          <!-- <h4 class="headline">具体游戏规则如下:</h4> -->
          <ul class="rule_ul">
            <li class="redTxt"> 正码 </li>
            <li> 投注的1个号码与当期摇出的20个号码中的任1个号码相同，则中奖。 </li>
            <li><span class="redTxt">总和</span></li>
            <li>以所有开出的全部20个号码加起来的和值来判定</li>
            <li><span class="redTxt">总和大/小</span></li>
            <li>20个号码加总的和值大于810，为和大；20个号码加总的和值小于810，则为和小。</li>
            <li><span class="redTxt">总和单/双</span></li>
            <li>20个号码加总的和值为单，叫做和单；20个号码加总的和值为双，叫做和双。</li>
            <li><span class="redTxt">总和810</span></li>
            <li>20个号码加总的和值等于810，叫和值810。(当和值等于810，则大小单双退回本金)举例：开奖号码为1，2，3，4，5，6，7，8，9，10，11，12，13，14，15，16，17，
              18，19，20；那么此20个开奖号码的和值总和为210，则为小，为双。则投注小和双者中奖。投注大、单、和值810者不中奖。</li>
            <li><span class="redTxt">总和过关</span></li>
            <li>开出的20个号码的总和的游戏，分为「大单」，「小单」，「大双」和「小双」</li>
            <li>总和大于810为「总数大」，小于810为「总数小」。</li>
            <li>总和为双数叫「双」，单数叫「单」。</li>
            <li>
              通过大小和单双组合产生「大单」，「小单」，「大双」和「小双」四种结果。
            </li>
            <li>总和等于810，则视为和局。</li>
            <li>
              举例：开奖号码为01、04、05、10、11、13、20、27、30、32、33、36、40、47、54、59、61、64、67、79，总和是693，总和小于810，并且是单数，则为「小单」。下注「小单」为
              赢，反之则输。
            </li>
            <li><span class="redTxt">前后和</span></li>
            <li>开奖号码1至40为前盘号码，41至80为后盘号码</li>
            <li>
              开出的20个号码中：如前盘号码（1-40）在此局开出号码数目占多数时，此局为：前(多)。
            </li>
            <li>
              开出的20个号码中，后盘号码（41-80）在此局开出号码数目占多数时，此局为：后(多)。
            </li>
            <li>
              通前盘号码（1－40）和后盘号码（41-80）在此局开出的数目相同时（各10个数字），此局为：前后(和)。且投注前（多）或后（多）视为不中奖，不返回投注金额。
            </li>
            <li>
              举例：此局开出1，2，3，4，5，6，7，8，9，10，11，12，13，14，15，16，17，18，19，20.
              此局为：前(多)。
            </li>
            <li>
              举例：此局开出41，42，43，44，45，46，47，48，49，50，51，52，53，54，55，56，57，58，59，60
              此局为：后(多)。
            </li>
            <li>
              举例：此局开出
              1，2，3，4，5，6，7，8，9，10，41，42，43，44，45，46，47，48，49，50
              此局为：前后(和)。
            </li>
            <li><span class="redTxt">单双和</span></li>
            <li>
              开奖号码中1，3，5，7，…，75，77，79为单数号码，2，4，6，8，……，76，78，80为双数号码。当期开出的20个中奖号码中，如单数号码数目占多数时（超过10个），则为单(多)，投注
              单(多)中奖；双数号码占多数时（超过10个），则为双(多)，投注双(多)中奖；如果单数和双数号码数目相同时（均为
              10个），则为单双(和)，投注单双(和)者中奖。且投注单（多）或双（多）视为不中奖，不返回下注金额。
            </li>
            <li>
              举例：此期开出1，3，5，7，9，11，13，15，17，19，21，22，24，26，28，30，32，34，46，68，
              其中单数11个双数9个，此期为：单(多)。
            </li>
            <li>
              举例：此期开出2，4，6，8，10，12，14，16，44，48，66，68，25，27，31，35，37，39，41，55，
              其中双数12个单数8个，此期为：双(多)。
            </li>
            <li>
              举例：此期开出2，4，6，8，10，12，14，16，18，20，41，43，45，47，49，51，53，55，57，59，
              其中单数10个双数10个，此期为：单双(和)。
            </li>
            <li><span class="redTxt">五行</span></li>
            <li>
              开出的20个号码的总和分在5个段，以金、木、水、火、土命名：金（210～695）、木（696～763）、水（764～855）、火（856～923）和土（924～1410）。
            </li>
            <li>
              举例：开奖号码为01、04、05、10、11、13、20、27、30、32、33、36、40、47、54、59、61、64、67、79，总和是693，则总分数在210－695段内，则开出的是「金」。下注「金」为赢，反之则输。
            </li>
          </ul>

        </div>
        <!-- 翻摊 -->

        <div class="rules" v-if="ruleTab=='fantan'">
          <div class="text">
            <h3 class="h3">具体游戏规则如下:</h3>
            <ol class="sscft-ol">
              <li>

                <p class="red">注明:余数得1为1番,余数得2为2番,余数得3为3番,余数得0为4番.</p>
                <table class="number-table">
                  <tbody>
                    <tr>
                      <th colspan="2">番摊中奖规则说明</th>
                    </tr>
                    <tr>
                      <th style="white-space: nowrap;">下注组合</th>
                      <th>说明</th>
                    </tr>
                    <tr>
                      <td>番</td>
                      <td>
                        <ul>
                          <li>投注单一号码，开出为赢，其余为输。</li>
                          <li>
                            如：
                            <ul class="inner-ul">
                              <li>1番，开1为赢，2、3、4为输</li>
                              <li>2番，开2为赢，1、3、4为输</li>
                              <li>3番，开3为赢，1、2、4为输</li>
                              <li>4番，开4为赢，1、2、3为输</li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>念</td>
                      <td>
                        <ul>
                          <li>投注两个号码，一个为赢，一个为和，另外两个为输。</li>
                          <li>
                            如：
                            <ul class="inner-ul">
                              <li>1念2，开1为赢，开2为和，3、4为输</li>
                              <li>1念3，开1为赢，开3为和，2、4为输</li>
                              <li>1念4，开1为赢，开4为和，2、3为输</li>
                              <li>2念3，开2为赢，开3为和，1、4为输</li>
                              <li>2念4，开2为赢，开4为和，1、3为输</li>
                              <li>2念1，开2为赢，开1为和，3、4为输</li>
                              <li>3念1，开3为赢，开1为和，2、4为输</li>
                              <li>3念2，开3为赢，开2为和，1、4为输</li>
                              <li>3念4，开3为赢，开4为和，1、2为输</li>
                              <li>4念3，开4为赢，开3为和，1、2为输</li>
                              <li>4念2，开4为赢，开2为和，1、3为输</li>
                              <li>4念1，开4为赢，开1为和，2、3为输</li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>角</td>
                      <td>
                        <ul>
                          <li>投注两个号码，开出为赢，其余为输。</li>
                          <li>
                            如：
                            <ul class="inner-ul">
                              <li>角1 2，开1、2为赢，3、4为输</li>
                              <li>角2 3，开2、3为赢，1、4为输</li>
                              <li>角3 4，开3、4为赢，1、2为输</li>
                              <li>角4 1，开4、1为赢，2、3为输</li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>双通</td>
                      <td>
                        <ul>
                          <li>投注两个号码，开出为赢，其余两个号码一输一和。</li>
                          <li>
                            如：
                            <ul class="inner-ul">
                              <li>23一通，则开2、3为赢，1为输，4为和</li>
                              <li>24一通，则开2、4为赢，1为输，3为和</li>
                              <li>34一通，则开3、4为赢，1为输，2为和</li>
                              <li>13二通，则开1、3为赢，2为输，4为和</li>
                              <li>14二通，则开1、4为赢，2为输，3为和</li>
                              <li>34二通，则开3、4为赢，2为输，1为和</li>
                              <li>12三通，则开1、2为赢，3为输，4为和</li>
                              <li>14三通，则开1、4为赢，3为输，2为和</li>
                              <li>24三通，则开2、4为赢，3为输，1为和</li>
                              <li>12四通，则开1、2为赢，4为输，3为和</li>
                              <li>13四通，则开1、3为赢，4为输，2为和</li>
                              <li>23四通，则开2、3为赢，4为输，1为和</li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>单通</td>
                      <td>
                        <ul>
                          <li>投注一个号码，开出为赢，其余三个一输二和。</li>
                          <li>
                            如：
                            <ul class="inner-ul">
                              <li>4一通，则开4为赢，1为输，23为和</li>
                              <li>2一通，则开2为赢，1为输，34为和</li>
                              <li>3二通，则开3为赢，2为输，14为和</li>
                              <li>1二通，则开1为赢，2为输，34为和</li>
                              <li>4三通，则开4为赢，3为输，12为和</li>
                              <li>2三通，则开2为赢，3为输，14为和</li>
                              <li>3四通，则开3为赢，4为输，12为和</li>
                              <li>1四通，则开1为赢，4为输，23为和</li>
                           
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>正</td>
                      <td>
                        <ul>
                          <li>投注一个号码，开出为赢，一个为输，另外两个为和。</li>
                          <li>
                            如：
                            <ul class="inner-ul">
                              <li>正1，则开1为赢，3为输，2、4为和</li>
                              <li>正2，则开2为赢，4为输，1、3为和</li>
                              <li>正3，则开3为赢，1为输，2、4为和</li>
                              <li>正4，则开4为赢，2为输，1、3为和</li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>三门</td>
                      <td>
                        <ul>
                          <li>
                            投注三个号码，如果开出的结果是三个号码之一，则中奖；其他则输。
                          </li>
                          <li>
                            如：
                            <ul class="inner-ul">
                              <li>三门234，开2、3、4为赢，1为输</li>
                              <li>三门134，开1、3、4为赢，2为输</li>
                              <li>三门124，开1、2、4为赢，3为输</li>
                              <li>三门123，开1、2、3为赢，4为输</li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>单双</td>
                      <td>
                        <ul>
                          <li>1、3为单</li>
                          <li>2、4为双</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </div>
        </div>
        <!-- 宾果幸运28   (pc蛋蛋)-->
        <div class="rules" style="box-sizing:border-box;padding-left:30px" v-if="ruleTab=='pcdd'">

          <!-- <img style="width:90%" src="../../assets/pcddshili.png" alt=""> -->
        </div>
        <div class="rules" v-if="ruleTab=='pcdd'">

          <ul class="rule_ul">
            <li class="first redTxtTitle">
              特码（和值）玩法：
            </li>
            <li>和值单双： 奇数为单，偶数为双；</li>
            <li>和值大小： 00-13为小，14-27为大；</li>
            <li>和值组合： 小单： 01 03 05 07 09 11 13；</li>
            <li>小双：00 02 04 06 08 10 12</li>
            <li>大单： 15 17 19 21 23 25 27</li>
            <li>大双： 14 16 18 20 22 24 26</li>
            <li>和值极值 ：00-05极小，22-27极大；</li>
            <li>和值(特码） ：00-27任点球；</li>
            <!-- --------------------------------------------------------------- -->
            <li class="first redTxtTitle">
              特码色波玩法：
            </li>
            <li>红波： 01 02 07 08 12 13 18 19 23 24</li>
            <li>蓝波： 03 04 09 10 14 15 20 25 26</li>
            <li>绿波： 00 05 06 11 16 17 21 22 27</li>
           
            <!-- --------------------------------------------------------------- -->
            <li class="first redTxtTitle">
              百家乐玩法：
            </li>
            <li>庄：第一球大于第三球</li>
            <li>闲：第一球小于第三球</li>
            <li>和：第一球等于第三球</li>
            <li>庄对：第一球等于第二球</li>
            <li>闲对：第二球等于第三球</li>
        
            <!-- --------------------------------------------------------------- -->
            <li class="first redTxtTitle">
              豹顺对玩法：
            </li>
            <li>豹子： 000,111，222，333，444，555，666，777，888，999；</li>
            <li>顺子： 开出的三个号码相连即为顺子(中奖号码的数字都相连（数字8,9,0不相连,9,0,1不相连），不分顺序。）</li>
            <li>对子： 开奖结果第一球，第二球，第三球3个数字中，任意重复两个数字即为对子；（例122,221,112,113,114）</li>
         
        
            <!-- --------------------------------------------------------------- -->
            <li class="first redTxtTitle">
              定胆玩法：
            </li>
            <li>单双：奇数为单，偶数为双；</li>
            <li>大小：0-4为小，5-9为大；</li>
            <li>定胆：从第一球，第二球，第三球任意位置上至少选择1个号码，所选号码与相同位置的开奖号码一致，即赢。</li>
         
        
            <!-- --------------------------------------------------------------- -->
           <!-- ------------------------------------------------------------------------------- -->
          </ul>

        </div>
        <!-- 宾果彩 -->
        <div class="rule" v-if="ruleTab=='bingo'">
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">1～10 两面：</span>指单、双；大、小、尾大、尾小。</li>
            <li><span class="redTxt">单、双：</span>号码为单数叫单，如1,3,5,7,51等为单;号码为双数叫双，如2,4,6,68等。</li>
            <li><span class="redTxt">大、小：</span>开出之号码大于或等于41为大，小于或等于40为小。</li>
            <li><span class="redTxt">尾数大、小：</span>开奖号码尾数大于或等于6为大，小于或等于5为小。</li>
            <li><span class="redTxt">合数单、双：</span>以指定出现正码的位置与号码个位和十位数字总和来判断单双。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">和值：</span>所有5个开奖号码的数字总和数值</li>
            <li><span class="redTxt">总和大、小：</span>数字总和值203-390为总大，数字总和值15到202为总小。</li>
            <li><span class="redTxt">总和单、双：</span>数字总和值是双数为总和双，数字总和值是单数为总和单。</li>
            <li><span class="redTxt">总和尾数大、小：</span>所有5个开奖号码的数字总和数值的个位数大于或等于5为总尾大，小于或等于4为总尾小。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">龙虎：</span> “平码一”大于“特码”视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖</li>
          </ul>
        </div>
        <!-- PK10 -->
        <div class="rule" v-if="ruleTab=='pk10'">
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">1～10 两面：</span>指单、双；大、小、尾大、尾小。</li>
            <li><span class="redTxt">单、双：</span>号码为双数叫双，如4、8；号码为单数叫单，如5、9。</li>
            <li><span class="redTxt">大、小：</span>开出之号码大于或等于6为大，小于或等于5为小。</li>
            <li><span class="redTxt">第一名～第十名 车号指定：</span>每一个车号为一投注组合，开奖结果“投注车号”对应所投名次视为中奖，其余情形视为不中奖。其余视为不中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">1～5龙虎</span></li>
            <li><span class="redTxt">冠 军 龙/虎：</span>“第一名”车号大于“第十名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</li>
            <li><span class="redTxt">亚 军 龙/虎：</span>“第二名”车号大于“第九名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</li>
            <li><span class="redTxt">第三名 龙/虎：</span>“第三名”车号大于“第八名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</li>
            <li><span class="redTxt">第四名 龙/虎：</span>“第四名”车号大于“第七名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</li>
            <li><span class="redTxt">第五名 龙/虎：</span>“第五名”车号大于“第六名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">冠军车号＋亚军车号＝冠亚和值（为3~19)</span></li>
            <li><span class="redTxt">冠亚和单双：</span>“冠亚和值”为单视为投注“单”的注单视为中奖，为双视为投注“双”的注单视为中奖，其余视为不中奖。</li>
            <li><span class="redTxt">冠亚和大小：</span>“冠亚和值”大于11时投注“大”的注单视为中奖，小于或等于11时投注“小”的注单视为中奖，其余视为不中奖。</li>
            <li><span class="redTxt">冠亚和指定：</span>“冠亚和值”可能出现的结果为3～19， 投中对应“冠亚和值”数字的视为中奖，其余视为不中奖。</li>
          </ul>
        </div>
        <!-- 11选5 -->
        <div class="rule" v-if="ruleTab=='11x5'">
          <div class="section_noindex blacktxt">
            <div style="padding-top:10px;">具体游戏规则如下:</div>
          </div>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span class="redTxtTitle">1.【单码】</span>- 指第一球、第二球、第三球、第四球、第五球出现的顺序与号码为派彩依据。</li>
            <li><span class="redTxt">单号：</span>如现场滚球第一个开奖号码为10号，投注第一球为10号则视为中奖，其它号码视为不中奖 大小：开出的号码大于或等于6为大，小于或等于5为小，开出11为和 (不计算输赢)</li>
            <li><span class="redTxt">单双：</span>号码为双数叫双，如2、8；号码为单数叫单，如5、9；开出11为和 (不计算输赢)。</li>
            <li class="first redTxtTitle"><span class="redTxtTitle">2.【总和】</span>-以全部开出的5个号码，加起来的总和来判定。</li>
            <li><span class="redTxt">总和大小: </span>所有开奖号码数字加总值大于30为和大；总和值小于30为和小；若总和值等于30为和 (不计算输赢)。</li>
            <li><span class="redTxt">总和单双: </span>所有开奖号码数字加总值为单数叫和单，如11、31；加总值为双数叫和双，如42、30。</li>
            <li><span class="redTxt">总和尾数大小:</span> 所有开奖号码数字加总值的尾数，大于或等于5为尾大，小于或等于4为尾小。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span class="redTxtTitle">3.【龙虎】</span></li>
            <li><span class="redTxt">龙：</span>第一球开奖号码大于第五球开奖号码，如第一球开出10，第五球开出7。</li>
            <li><span class="redTxt">虎：</span>第一球开奖号码小于第五球开奖号码，如第一球开出3，第五球开出7。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span class="redTxtTitle">4.选号</span>- 选号玩法是由1~11号中，选出1~5个号码为一投注组合来进行投注。</li>
            <li><span class="redTxt">一中一: </span>投注1个号码与当期开奖的5个号码中任1个号码相同，视为中奖。</li>
            <li><span class="redTxt">任选二:</span> 投注2个号码与当期开奖的5个号码中任2个号码相同(顺序不限)，视为中奖。</li>
            <li><span class="redTxt">任选三: </span>投注3个号码与当期开奖的5个号码中任3个号码相同(顺序不限)，视为中奖。</li>
            <li><span class="redTxt">任选四:</span> 投注4个号码与当期开奖的5个号码中任4个号码相同(顺序不限)，视为中奖。</li>
            <li><span class="redTxt">任选五:</span> 投注5个号码与当期开奖的5个号码中5个号码相同(顺序不限)，视为中奖。</li>
            <li><span class="redTxt">任选六:</span> 投注6个号码中任5个号码与当期开奖的5个号码相同(顺序不限)，视为中奖。</li>
            <li><span class="redTxt">任选七:</span> 投注7个号码中任5个号码与当期开奖的5个号码相同(顺序不限)，视为中奖。</li>
            <li><span class="redTxt">任选八:</span> 投注8个号码中任5个号码与当期开奖的5个号码相同(顺序不限)，视为中奖。</li>
            <li><span class="redTxt">组选前二:</span> 投注的2个号码与当期顺序开出的5个号码中的前2个号码相同，视为中奖。</li>
            <li><span class="redTxt">组选前三:</span> 投注的3个号码与当期顺序开出的5个号码中的前3个号码相同，视为中奖。</li>
            <li><span class="redTxt">直选前二:</span> 投注的2个号码与当期顺序开出的5个号码中的前2个号码相同且顺序一致，视为中奖。</li>
            <li><span class="redTxt">直选前三:</span> 投注的3个号码与当期顺序开出的5个号码中的前3个号码相同且顺序一致，视为中奖。</li>
          </ul>

        </div>
        <!-- 快3 -->
        <div class="rule" v-if="ruleTab=='k3'">
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">三军</span></li>
            <li>任何一投注号码出现在开出的号码中，即为中奖【中奖结果：一同骰=（赔率-1）X1、二同骰=(赔率-1)x2、三同骰=(赔率-1)x3】。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">和值</span></li>
            <li>开奖结果佰拾个三位数的总和值与投注佰拾个数字的总和值相同时，即为中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">和值双面</span></li>
            <li><span class="redTxt">和值大小 :</span> 3~10为小，11~18为大。</li>
            <li><span class="redTxt">和值单双 :</span> 3、5、7、9、11、13、15、17为单，4、6、8、10、12、14、16、18为双。</li>
            <li><span class="redTxt">和值大小单 :</span> 3、5、7、9为小单，11、13、15、17即为大单。</li>
            <li><span class="redTxt">和值大小双 :</span> 4、6、8、10为小双，12、14、16、18为大双。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">选号玩法</span></li>
            <li><span class="redTxt">三同号单选</span></li>
            <li>从三同号(111、222、333、444、555、666)中任选一个进行投注，开奖号码为此三同号即中奖。</li>
            <li><span class="redTxt">二同号复选</span></li>
            <li>开奖号码中的二同号数字(即对子号码)，非对子不中奖。</li>
            <li><span class="redTxt">二同号单选</span></li>
            <li>开奖号码中的二同号数字(即对子号码)，和不同号的数字。</li>
            <li><span class="redTxt">三不同号</span></li>
            <li>三不同号是指开奖号码的三个数子都不相同，竟猜这三个数字，猜对即中奖。</li>
            <li><span class="redTxt">二不同号</span></li>
            <li>选择两个号码进行投注，开奖号码中包含这两个号码即中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">豹、顺、对、杂、红、黑</span></li>
            <li><span class="redTxt">豹子(三同号通选)</span></li>
            <li>对所有三同号进行投注，开奖号码为任意三同号(111、222、333、444、555、666) 即中奖</li>
            <li><span class="redTxt">全顺(三连号通选)</span></li>
            <li>对所有三连号进行投注，开奖号码为任意三连号(123、234、345、456) 即中奖。</li>
            <li><span class="redTxt">半顺</span></li>
            <li>三号码中任二连号进行投注，开奖号码中任二码连号(124、125、126、134、145、156、235、236、245、256、346、356)即为中奖；开出全顺及三同号者视为不中奖。</li>
            <li><span class="redTxt">杂</span>
            </li>
            <li>对所有非三连号进行投注，开奖号码为任意3个非连号(135、136、146、246)即为中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">跨度</span></li>
            <li>跨度为开出奖号的最大数与最小值之差值为跨度(例如开出123则为2跨)。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">和值过关</span></li>
            <li><span class="redTxt">红码</span>
            </li>
            <li>对所有二同号进行投注，开奖号码出现对子(不含豹子)，投注红码即中奖(例如112、224等)。</li>
            <li><span class="redTxt">黑码</span>
            </li>
            <li>开出三个不同号即为黑码(例如123、245等)。</li>
            <li><span class="redTxt">红单双</span>
            </li>
            <li>开出奖号属于红码，且和值为单则为红单，若和值为双即为红双。</li>
            <li><span class="redTxt">红大小</span>
            </li>
            <li>开出奖号属于红码，且和值为大则为红大，若和值为小即为红小。</li>
            <li><span class="redTxt">黑单双</span>
            </li>
            <li>开出奖号属于黑码，且和值为单则为黑单，若和值为双即为黑双。</li>
            <li><span class="redTxt">黑大小</span></li>
            <li>开出奖号属于黑码，且和值为大则为黑大，若和值为小即为黑小。</li>
            <li><span class="redTxt">黑码组合 </span></li>
            <li>投注任意挑选择3~6个号码投注，开出奖号为三个不同号码全部在投注的号码当中 (例如投注 1234，若开出 123 124 134 234)视为中奖。</li>
            <li><span class="redTxt">红码组合</span></li>
            <li>投注任意挑选择2~6个号码投注，开奖号码其中有两个号码是相同的并且开出的号码全部都在所选的投注号码当中(例如投注 1234，若开出 112、113、114、223、224、334、344)则视为中奖。</li>
          </ul>
        </div>
        <!-- 快乐十分 -->
        <div class="rule" v-if="ruleTab=='klsf8'">
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">1.两面：</span>指单、双；大、小、尾大、尾小。
            </li>
            <li>单、双：号码为双数叫双，如8、16；号码为单数叫单，如19、5。</li>
            <li>大、小：开出之号码大于或等于11为大，小于或等于10为小。</li>
            <li>尾大、尾小：开出之尾数大于或等于5为尾大，小于或等于4为尾小。</li>
            <li>每一个号码为一投注组合，假如投注号码为开奖号码并在所投的球位置，视为中奖，其余情形视为不中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">2.中发白</span></li>
            <li class="">中：开出之号码为01、02、03、04、05、06、07</li>
            <li class="">发：开出之号码为08、09、10、11、12、13、14</li>
            <li class="">白：开出之号码为15、16、17、18、19、20</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">3.方位</span></li>
            <li class="">东：开出之号码为01、05、09、13、17</li>
            <li class="">南：开出之号码为02、06、10、14、18</li>
            <li class="">西：开出之号码为03、07、11、15、19</li>
            <li class="">北：开出之号码为04、08、12、16、20</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">4.四季</span></li>
            <li class="">春：开出之号码为01、02、03、04、05</li>
            <li class="">夏：开出之号码为06、07、08、09、10</li>
            <li class="">秋：开出之号码为11、12、13、14、15</li>
            <li class="">冬：开出之号码为16、17、18、19、20</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">5.单号1-8（第一球至第八球）：</span>自第一球至第八球，每一个号码为一投注项目，如投注号码为开奖号码且在所投的球位置，视为中奖，其余情形视为不中奖。余情形视为不中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">6.龙虎：</span>以第一球的中奖号码和第八球的中奖号码做为对奖号码。</li>
            <li><span class="redTxt">龙：</span>开出之号码第一球的中奖号码大于第八球的中奖号码。如 第一球开出14 第八球开出09；第一球开出17 第八球开出08；第一球开出05 第八球开出01...中奖为龙。</li>
            <li><span class="redTxt">虎：</span>开出之号码第一球的中奖号码小于第八球的中奖号码。如 第一球开出14 第八球开出16；第一球开出13 第八球开出18；第一球开出05 第八球开出08...中奖为虎。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">7.总和单双：</span>所有8个开奖号码的数字总和值是单数为总和单，如数字总和值是31、51；所有8个开奖号码的数字总和值是双数为总和双，如数字总和是42、80；假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">8.总和大小：</span>所有8个开奖号码的数字总和值85到132为总大；所有8个开奖号码的数字总和值36到83为总分小；所有8个开奖号码的数字总和值为84打和；如开奖号码为01、20、02、08、17、09、11，数字总和是68，则总分小。假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖，打和不计算结果。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">9.总尾大小：</span>所有8个开奖号码的数字总和数值的个位数大于或等于5为总尾大，小于或等于4为总尾小；假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">10.尾大、尾小：</span>自第一球至第八球，号码尾数大于或等于5则为尾大，小于或等于4则为尾小。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">11.合单：</span>指开出球号的个位数加上十位数的总和是“单数”，如53、85、99。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">12.合双：</span>指开出球号的个位数加上十位数的总和是“双数”，如8、38、66。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">13.选二任选：</span>指从01至20中任意选择2个号码对开奖号码中任意2个位置的投注。 投注号码与开奖号码中任意2个位置的号码相符，即中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">14.选三任选：</span>指从01至20中任意选择3个号码对开奖号码中任意3个位置的投注。 投注号码与开奖号码中任意3个位置的号码相符，即中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">15.选四任选：</span>指从01至20中任意选择4个号码，对开奖号码中任意4个位置的投注。 投注号码与开奖号码中任意4个位置的号码相符，即中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">16.选五任选：</span>指从01至20中任意选择5个号码，对开奖号码中任意5个位置的投注。 投注号码与开奖号码中任意5个位置的号码相符，即中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">17.选二连组：</span>指从01至20中任意选择2个号码对开奖号码中按开奖顺序出现的2个连续位置的投注。 投注号码与开奖号码中按开奖顺序出现的2个连续位置的号码相符（顺序不限），即中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">18.选三前组：</span>指从01至20中任意选择3个号码对开奖号码中按开奖顺序出现的前3个连续位置的投注。 投注号码与开奖号码中按开奖顺序出现的前3个位置的号码相符（顺序不限），即中奖。
            </li>
          </ul>
        </div>
        <!-- 排列3 -->
        <div class="rule" v-if="ruleTab=='pl3'">
          <ul class="rule_ul">
            <li class="first redTxtTitle">
              选号玩法
            </li>
            <li><span class="redTxt">一字定位</span>
            </li>
            <li>
              于佰拾个任选一位，自0~9任选1号进行投注，当开奖结果与所选的定位与号码相同时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买一字佰定位，选择号码为1，当期开奖结果如果只要佰位数为1，拾位及个位数无论为1xx皆视为中奖。（x=0~9任一数）
            </li>
            <li><span class="redTxt">二字定位</span>
            </li>
            <li>
              于佰拾个任选二位，自0~9任选2号进行投注，当开奖结果与所选号码相同且顺序一致时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买二字佰拾定位，选择号码为佰位1、拾位2，当期开奖结果如果只要佰位与拾位皆与其所选的号码相同且顺序一致时，个位数无论为12x皆视为中奖。（x=0~9任一数）
            </li>
            <li><span class="redTxt">三字定位</span>
            </li>
            <li>
              于佰拾个位自0~9任选3号进行投注，当开奖结果与所选号码相同且顺序一致时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买三字佰拾个定位，选择号码为123，当期开奖结果如为123，即视为中奖。
            </li>
            <li>
              ※附注：本项投注不包括上下附奖。
            </li>
            <li><span class="redTxt">一字组合</span>
            </li>
            <li>
              0~9任选1号进行投注，当开奖结果佰拾个任一数与所选的号码相同时，即为中奖；若开奖结果出现重覆数字时，视为中奖一次。
            </li>
            <li>
              ※举例：投注者购买一字组合，选择号码为1，当期开奖结果如为1xx、x1x、xx1皆视为中奖。（x=0~9任一数）；若开奖的结果为11x、1x1、x11或111仅视为中奖一次。
            </li>
            <li><span class="redTxt">二字组合</span>
            </li>
            <li>
              0~9任选2号进行投注，当开奖结果佰拾个任二数与所选的号码相同时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买二字组合，选择2个相同号码如为11，当期开奖结果如为11x、1x1、x11、皆视为中奖。（x=0~9任一数）
            </li>
            <li>
              ※举例：投注者购买二字组合，选择2个不同号码如为12，当期开奖结果如为12x、1x2、21x、2x1、x12、x21皆视为中奖。（x=0~9任一数）
            </li>
            <li>
              【附注】：以上二例赔率不同
            </li>
            <li><span class="redTxt">三字组合</span>
            </li>
            <li>
              三字：自0~9号任选3个皆相同的号码时（如111），当开奖结果与所选号码相同时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买三字组合，选择号码为111，当期开奖结果如为111则视为中奖。
            </li>
            <li>
              组三：自0~9号任选3号其中2个号相同时（如112），当开奖结果与所选号码相同但顺序不同时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买三字组合，选择号码为112，当期开奖结果如为112、121、211皆视为中奖。
            </li>
            <li>
              组六：自0~9号任选3号且3个号都不同时（如123），当开奖结果与所选号码相同但顺序不同时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买三字组合，选择号码为123，当期开奖结果如为123、132、213、231、312、321皆视为中奖。
            </li>
            <li>
              【附注】：以上三种投注组合其赔率皆不同
            </li>
            <li><span class="redTxt">組选三</span>
            </li>
            <li>
              会员可以挑选5~10个号码，当开奖结果中有且只有两个号码重复，则视为中奖。挑选不同个数号码有其相对应的赔率。如果是选择 1、2、3、4、5中的任何两个号码，且其中有一个号码重复则中奖。
            </li>
            <li>
              ※举例：112、344，若是开出豹子则不算中奖。
            </li>
            <li>
              【附注】："豹子"为三字同号，例如：111、222
            </li>
            <li><span class="redTxt">組选六</span>
            </li>
            <li>
              会员可以挑选择4~8个号码，当开奖结果都出现在所下注的号码中且没有任何号码重复，则视为中奖。挑选不同的号码有其相对应的赔率，中奖赔率以所选号码中的最小赔率计算彩派。
            </li>
            <li>
              ※举例：如果是选择(1、2、3、4)，则开奖结果为123、124、134、234都中奖，其他都是不中奖。例如：112、133、145、444等都是不中奖。
            </li>
            <li><span class="redTxt">复式组合</span>
            </li>
            <li>
              在佰、拾、个中分别选择号码组合。例如选择3x2x4，即佰位数选3个号码，拾位数选2个号码，个位数选4个号码或选择3x3x3，如每个位数所选择的号码都对应出现在开奖结果中，则为中奖。
            </li>
            <li>
              此游戏选号有以下限制：
            </li>
            <li>
              每一位数最少要选择1个号码，最多选10个号码。<br>
              三个位数共选取号码数量，最少需选择9个号，最多选择21个号码
            </li>
            <li><span class="redTxt">一字过关</span>
            </li>
            <li>
              以开奖三个号之大小、单双、质合作为中奖的依据。
            </li>
            <li>
              ※举例：开奖结果为3、4、8。会员若在佰定位下小、拾定位下双、个定位下合。则视为中奖。
            </li>
            <li><span class="redTxt">跨度</span>
            </li>
            <li>
              以开奖三个号码的最大差距(跨度)，作为中奖的依据。会员可以选择0~9的任一跨度
            </li>
            <li>
              ※举例：开奖结果为3，4，8。中奖的跨度为5。(最大号码 8减最小号码 3 = 5)。若开奖结果三号码都相同，称作豹子，投注注豹子者中奖，其馀为不中奖。
            </li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span class="redTxtTitle">
                和值玩法
              </span></li>
            <li><span class="redTxt">合数说明</span>
            </li>
            <li>
              开奖结果佰拾个三位数的总和值与若投注佰拾个位数字的总和值与相同时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买合数1，当期开奖结果如为001、010、100此三种和皆为1，则视为中奖。
            </li>
            <li><span class="redTxt">佰拾个合数尾数说明</span>
            </li>
            <li>
              開獎結果佰拾個三位數的總和值的個位數字與若投注佰拾個位數字的總和值的個位數與相同時，即為中獎。
            </li>
            <li>
              ※举例：投注者购买佰拾个合尾数3，当期开奖结果如为373、490、968此三种和的尾数皆为3，则视为中奖。
            </li>
            <li><span class="redTxt">二字定位合尾数说明</span>
            </li>
            <li>
              开奖结果佰拾、佰个、拾个二位数的总和值尾数若与投注佰拾、佰个、拾个二位数的总和值尾数与相同时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买拾个合尾数4，当期开奖结果如为168、295、577此三种和的尾数皆为4，则视为中奖。
            </li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span class="redTxtTitle">
                两面玩法
              </span></li>
            <li><span class="redTxt">单双玩法说明</span>
            </li>
            <li>
              开奖结果佰拾位、拾个位或佰个位数字总和的个位数为1、3、5、7、9时为“单”，若为0、2、4、6、8时为“双”，当投注合数单双与开奖结果的合数单双相符时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买合数佰拾位单，当期开奖结果如为290（佰2+拾9=11为单），则视为中奖。
            </li>
            <li>
              开奖结果佰位、拾位或个位数字为1、3、5、7、9时为“单”，若为0、2、4、6、8时为“双”，当投注合数单双与开奖结果的合数单双相符时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买合数佰位单，当期开奖结果如为130（1为单），则视为中奖。
            </li>
            <li>
              <div class="tab_container">
                <table class="tab_style">
                  <tbody>
                    <tr>
                      <th>
                        单
                      </th>
                      <th>
                        双
                      </th>
                    </tr>
                    <tr>
                      <td>
                        1、 3、 5、 7、 9
                      </td>
                      <td>
                        0、 2、 4、 6、 8
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              开奖结果佰拾个位数字总和的为1、3、5、7、9、11、13、15、17、19、21、21、23、25、27时为“单”， 若为0、2、4、6、8、10、12、14、16、18、20、22、24、26时为“双”，当投注合数单双与开奖结果的合值单双相符时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买合值单，当期开奖结果如为290（佰2+拾9+个0=11为单），则视为中奖。
            </li>
            <li>
              <div class="tab_container">
                <table class="tab_style">
                  <tbody>
                    <tr>
                      <th>
                        单
                      </th>
                      <th>
                        双
                      </th>
                    </tr>
                    <tr>
                      <td>
                        1、 3、 5、 7、 9、11、13、<br>15、17、19、21、23、25、27
                      </td>
                      <td>
                        0、 2、 4、 6、 8、10、12、<br>14、16、18、20、22、24、26
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li><span class="redTxt">大小玩法说明</span>
            </li>
            <li>
              开奖结果佰位、拾位或个位数字为5、6、7、8、9时为“大”，若为0、1、2、3、4时为“小”，当投注位數大小与开奖结果的位數大小相符时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买佰位小，当期开奖结果如为352（3为小），则视为中奖。
            </li>
            <li>
              开奖结果佰拾位、拾个位或佰个位数字总和的个位数为5、6、7、8、9时为“大”，若为0、1、2、3、4时为“小”，当投注合数大小与开奖结果的合数大小相符时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买合数佰拾位小，当期开奖结果如为491（佰4+拾9=13，尾数3为小），则视为中奖。
            </li>
            <li>
              <div class="tab_container">
                <table class="tab_style">
                  <tbody>
                    <tr>
                      <th>
                        大
                      </th>
                      <th>
                        小
                      </th>
                    </tr>
                    <tr>
                      <td>
                        5、 6、 7、 8、 9
                      </td>
                      <td>
                        0、 1、 2、 3、 4
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              开奖结果佰拾个位数字总和的为14、15、16、17、18、19、20、21、22、23、24、25、26、27时为“大”， 若为0、1、2、3、4、5、6、7、8、9、10、11、12、13时为“小”，当投注合数单双与开奖结果的合值大小相符时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买合值大，当期开奖结果如为976（佰9+拾7+个6=22为大），则视为中奖。
            </li>
            <li>
              <div class="tab_container">
                <table class="tab_style">
                  <tbody>
                    <tr>
                      <th>
                        大
                      </th>
                      <th>
                        小
                      </th>
                    </tr>
                    <tr>
                      <td>
                        14、15、16、17、18、19、20、21、<br>
                        22、23、24、25、26、27
                      </td>
                      <td>
                        0、1、2、3、4、5、6、 <br>
                        7、8、9、10、11、12、13
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li><span class="redTxt">质合玩法说明</span>
            </li>
            <li>
              开奖结果佰位、拾位或个位数字为1、2、3、5、7时为“质数”，若为0、4、6、8、9时为“合数”，当投注位数质合与开奖结果的位数质合相符时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买个位质，当期开奖结果如为957（7为质），则视为中奖。
            </li>
            <li>
              开奖结果佰拾位、拾个位或佰个位数字总和的个位数为1、2、3、5、7时为“质数”，若为0、4、6、8、9时为“合数”，当投注号码与开奖结果的质合相符时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买佰拾位合，当期开奖结果如为957（佰9+拾5=14，尾数4为合数），则视为中奖。
            </li>
            <li>
              开奖结果佰拾个位数字总和的个位数为1、2、3、5、7时为“质数”，若为0、4、6、8、9时为“合数”，当投注号码与开奖结果的质合相符时，即为中奖。
            </li>
            <li>
              ※举例：投注者购买佰拾个位質，当期开奖结果如为957（佰9+拾5+个7=21，尾数1为質数），则视为中奖。
            </li>
            <li>
              <div class="tab_container">
                <table class="tab_style">
                  <tbody>
                    <tr>
                      <th>
                        质
                      </th>
                      <th>
                        合
                      </th>
                    </tr>
                    <tr>
                      <td>
                        1、 2、 3、 5、 7
                      </td>
                      <td>
                        0、 4、 6、 8、 9
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
        <!-- 六合彩 -->
        <div class="rule" v-if="ruleTab=='hk6'">

          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>1.特码</span></li>
            <li>当期开出的最後一码为特码。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>2.两面</span></li>
            <li class="redTxt">特码大小</li>
            <li>特小：开出的特码，(01~24)小于或等于24。</li>
            <li>特大：开出的特码，(25~48)小于或等于48。</li>
            <li>和局：特码为49时。</li>
            <br>
            <li class="redTxt">特码单双</li>
            <li>特双：特码为双数，如18、20、34、42。</li>
            <li>特单：特码为单数，如01，11，35，47。</li>
            <li>和局：特码为49时。</li>
            <br>
            <li class="redTxt">特码合数大小</li>
            <li>特合大：以特码的个位和十位数字之和来判定胜负，介于7-12为合大。例如：07、34、48。</li>
            <li>特合小：以特码的个位和十位数字之和来判定胜负，介于1-6为合小。例如：01、24、33。</li>
            <li>和局：特码为49时。</li>
            <br>
            <li class="redTxt">特码合数单双</li>
            <li>特合双：指开出特码的个位加上十位之和为‘双数’，如02、11、33、44。</li>
            <li>特合单：指开出特码的个位加上十位之和为‘单数’，如01、14、36、47。</li>
            <li>和局：特码为49时。</li>
            <br>
            <li class="redTxt">特码：特天肖，特地肖</li>
            <li>开出的特码属于十二生肖中的牛、兔、龙、马、猴、猪号码为特天肖。</li>
            <li>开出的特码属于十二生肖中的鼠、虎、蛇、羊、鸡、狗号码为特地肖。</li>
            <li>开出49则视为和局。</li>
            <br>
            <li class="redTxt">特码：特前肖，特后肖</li>
            <li>开出的特码属于十二生肖中的鼠、牛、虎、兔、龙、蛇号码为特前肖。</li>
            <li>开出的特码属于十二生肖中的马、羊、猴、鸡、狗、猪号码为特后肖。</li>
            <li>开出49则视为和局。</li>
            <br>
            <li class="redTxt">特码：特家肖，特野肖</li>
            <li>开出的特码属于十二生肖中的牛、马、羊、鸡、狗、猪号码为家禽。</li>
            <li>开出的特码属于十二生肖中的鼠、虎、龙、蛇、兔、猴号码为野兽。</li>
            <li>开出49则视为和局。</li>
            <br>
            <li class="redTxt">特码尾数大小</li>
            <li>特大尾：5尾~9尾为大，如05、18、19。</li>
            <li>特小尾：0尾~4尾为小，如01、32、44。</li>
            <li>和局：特码为49时。</li>
            <br>
            <li class="redTxt">总和单双</li>
            <li>总和单：所以七个开奖号码的分数总和是‘单数’，如分数总和是133、197。</li>
            <li>总和双：所以七个开奖号码的分数总和是‘双数’，如分数总和是120、188。</li>
            <br>
            <li class="redTxt">总和大小</li>
            <li>总和大：所以七个开奖号码的分数总和大于或等于175。</li>
            <li>总和小：所以七个开奖号码的分数总和小于或等于174。</li>
            <br>
            <li class="redTxt">特码半特</li>
            <li>以特码大小与特码单双游戏为一个投注组合；当期特码开出符合投注组合，即视为中奖；若当期特码开出49号，其余情形视为不中奖。</li>
            <li>特大单：25、27、29、31、33、35、37、39、41、43、45、47。</li>
            <li>特大双：26、28、30、32、34、36、38、40、42、44、46、48。</li>
            <li>特小单：01、03、05、07、09、11、13、15、17、19、21、23。</li>
            <li>特小双：02、04、06、08、10、12、14、16、18、20、22、24。</li>
            <li>和局：特码为49时。</li>
            <br>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>3.色波半波</span></li>
            <li>49个号码球分别有红、蓝、绿三种颜色，以特码开出的颜色和投注的颜色相同视为中奖，颜色代表如下:</li>
            <li>红波：01 ,02 ,07 ,08 ,12 ,13 ,18 ,19 ,23 ,24 ,29 ,30 ,34 ,35 ,40 ,45 ,46</li>
            <li>蓝波：03 ,04 ,09 ,10 ,14 ,15 ,20 ,25 ,26 ,31 ,36 ,37 ,41 ,42 ,47 ,48</li>
            <li>绿波：05 ,06 ,11 ,16 ,17 ,21 ,22 ,27 ,28 ,32 ,33 ,38 ,39 ,43 ,44 ,49</li>
            <li>半波：以特码色波和红单，红双，红大，红小，蓝单，蓝双，蓝大，蓝小，绿单，绿双，绿大，绿小为一个投注组合，当期特码开出符合投注组合，即视为中奖；若当期特码开出49号则视为和局；其余情形视为不中奖。</li>
            <li>半半波：以特码色波和红大单，红大双，红小单，红小双，蓝大单，蓝大双，蓝小单，蓝小双，绿大单，绿大双，绿小单，绿小双为一个投注组合，当期特码开出符合投注组合，即视为中奖；若当期特码开出49号则视为和局；其余情形视为不中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>4.特肖头尾数</span></li>
            <li class="redTxt">特码生肖</li>
            <li>生肖顺序为 鼠 &gt;牛 &gt;虎 &gt;兔 &gt;龙 &gt;蛇 &gt;马 &gt;羊 &gt;猴 &gt;鸡 &gt;狗 &gt;猪 。</li>
            <li>如今年是牛年，就以牛为开始，依顺序将49个号码分为12个生肖『如下』</li>
            <li>牛: 01、13、25、37、49</li>
            <li>鼠: 02、14、26、38</li>
            <li>猪: 03、15、27、39</li>
            <li>狗: 04、16、28、40</li>
            <li>鸡: 05、17、29、41</li>
            <li>猴: 06、18、30、42</li>
            <li>羊: 07、19、31、43</li>
            <li>马: 08、20、32、44</li>
            <li>蛇: 09、21、33、45</li>
            <li>龙: 10、22、34、46</li>
            <li>兔: 11、23、35、47</li>
            <li>虎: 12、24、36、48</li>
            <li>若当期特别号，落在下注生肖范围内，视为中奖 。</li>
            <br>
            <li class="redTxt">特码头数：是指特码属头数的号码</li>
            <li>"0"头：01、02、03、04、05、06、07、08、09</li>
            <li>"1"头：10、11、12、13、14、15、16、17、18、19</li>
            <li>"2"头：20、21、22、23、24、25、26、27、28、29</li>
            <li>"3"头：30、31、32、33、34、35、36、37、38、39</li>
            <li>"4"头：40、41、42、43、44、45、46、47、48、49</li>
            <li>例如：开奖结果特别号码为21则2头为中奖，其他头数都不中奖。</li>
            <br>
            <li class="redTxt">特码尾数：是指特码属尾数的号码。</li>
            <li>"0"尾：10、20、30、40</li>
            <li>"1"尾：01、11、21、31、41</li>
            <li>"2"尾：02、12、22、32、42</li>
            <li>"3"尾：03、13、23、33、43</li>
            <li>"4"尾：04、14、24、34、44</li>
            <li>"5"尾：05、15、25、35、45</li>
            <li>"6"尾：06、16、26、36、46</li>
            <li>"7"尾：07、17、27、37、47</li>
            <li>"8"尾：08、18、28、38、48</li>
            <li>"9"尾：09、19、29、39、49</li>
            <li>例如：开奖结果特别号码为21则1尾数为中奖，其他尾数都不中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>5.合肖</span></li>
            <li>挑选2-11个生肖『排列如同生肖』为一个组合，并选择开奖号码的特码是否在此组合内『49号除外』，即视为中奖；若当期特码开出49号，则所有组合皆视为和局。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>6.正码</span></li>
            <li>当期开出之前6个号码叫正码。每一个号码为一投注组合，假如投注号码为开奖号码之正码，视为中奖，其余情形视为不中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>7.正码特</span></li>
            <li>正码特是指 正1特、正2特、正3特、正4特、正5特、正6特。</li>
            <li>其下注的正码特号与现场摇珠开出之正码其开奖顺序及开奖号码相同，视为中奖。</li>
            <li>如现场摇珠第一个正码开奖为49号，下注第一个正码特为49则视为中奖，其它号码视为不中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>8.正码1-6</span></li>
            <li>当期开出之前6个号码叫正码。第一时间出来的叫正码1，依次为正码2、正码3┅┅ 正码6(并不以号码大小排序)。</li>
            <li>大小:以指定出现正码的位置与号码大于或等于25为大，小于或等于24为小，开出49为和。</li>
            <li>单双:以指定出现正码的位置与号码为单数或双数下注，开出49为和</li>
            <li>合数大小:以指定出现正码的位置与号码个位和十位数字总和来判断胜负，和数大于或等于7为大，小于或等于6为小，开出49号为和。</li>
            <li>合数单双:以指定出现正码的位置与号码个位和十位数字总和来判断单双，开出49号为和。</li>
            <li>尾数大小:以指定出现正码的位置与号码尾数大小下注，若0尾~4尾为小、5尾~9尾为大。如01、32、44为正尾小；如05、18、19为正尾大，开出49号为和。</li>
            <li>色波:以指定出现正码的位置的球色下注，开奖之球色与下注之颜色相同时，视为中奖，其余情形视为不中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>9.五行</span></li>
            <li>挑选一个五行选项为一个组合，若开奖号码的特码在此组合内，即视为中奖；若开奖号码的特码亦不在此组合内，即视为不中奖；</li>
            <li>金: 07,08,21,22,29,30,37,38</li>
            <li>木: 03,04,11,12,19,20,33,34,41,42,49</li>
            <li>水: 09,10,17,18,25,26,39,40,47,48</li>
            <li>火: 05,06,13,14,27,28,35,36,43,44</li>
            <li>土: 01,02,15,16,23,24,31,32,45,46</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>10.一肖总肖平特尾数</span></li>
            <li class="redTxt">一肖</li>
            <li>指开奖的7个号码中含有所属生肖的一个或多个号码，但派彩只派一次，即不论同生肖号码出现一个或多个号码都只派一次。</li>
            <li>牛: 01、13、25、37、49</li>
            <li>鼠: 02、14、26、38</li>
            <li>猪: 03、15、27、39</li>
            <li>狗: 04、16、28、40</li>
            <li>鸡: 05、17、29、41</li>
            <li>猴: 06、18、30、42</li>
            <li>羊: 07、19、31、43</li>
            <li>马: 08、20、32、44</li>
            <li>蛇: 09、21、33、45</li>
            <li>龙: 10、22、34、46</li>
            <li>兔: 11、23、35、47</li>
            <li>虎: 12、24、36、48</li>
            <li>只要当期号码 (所有正码与最后开出的特码)，落在下注生肖范围内，则视为中奖。(请注意：49亦算输赢，不为和)。</li>
            <br>
            <li class="redTxt">尾数</li>
            <li>指开奖的7个号码中含有所属尾数的一个或多个号码，但派彩指派一次，即不论同尾数号码出现一个或多个号码都指派一次。</li>
            <li>"0"尾：10、20、30、40</li>
            <li>"1"尾：01、11、21、31、41</li>
            <li>"2"尾：02、12、22、32、42</li>
            <li>"3"尾：03、13、23、33、43</li>
            <li>"4"尾：04、14、24、34、44</li>
            <li>"5"尾：05、15、25、35、45</li>
            <li>"6"尾：06、16、26、36、46</li>
            <li>"7"尾：07、17、27、37、47</li>
            <li>"8"尾：08、18、28、38、48</li>
            <li>"9"尾：09、19、29、39、49</li>
            <li>例如：开奖结果正码号码为11、31、42、44、35、32特别号码为21则1尾2尾4尾5尾都为中奖，其他尾数都不中奖。</li>
            <br>
            <li class="redTxt">总肖</li>
            <li>当期号码(所有正码与最後开出的特码)开出的不同生肖总数，与所投注之预计开出之生肖总数合(不用指定特定生肖)，则视为中奖，其余情形视为不中奖。例如：如果当期号码为19、24、12、34、40、39 特别号：49，总计六个生肖，若选总肖【6】则为中奖(请注意：49号亦算输赢，不为和）。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>11.正肖七色波</span></li>
            <li class="redTxt">正肖</li>
            <li>以开出的6个正码做游戏，只要有1个落在下注生肖范围内，视为中奖。如超过1个正码落在下注生肖范围内 ，派彩将倍增！如：下注＄100.-正肖龙倍率1.8。</li>
            <li>6个正码开出01，派彩为$80</li>
            <li>6个正码开出01、13，派彩为$160</li>
            <li>6个正码开出01、13、25，派彩为$240</li>
            <li>6个正码开出01、13、25、37，派彩为$320</li>
            <li>6个正码开出01、13、25、37、49，派彩为$400（请注意：49亦算输赢，不为和）</li>
            <br>
            <li class="redTxt">七色波</li>
            <li>以开出的7个色波，那种颜色最多为中奖。 开出的6个正码各以1个色波计，特别号以1.5个色波计。而以下3种结果视为和局。</li>
            <li>1： 6个正码开出3蓝3绿，而特别码是1.5红 </li>
            <li>2： 6个正码开出3蓝3红，而特别码是1.5绿 </li>
            <li>3： 6个正码开出3绿3红，而特别码是1.5蓝</li>
            <li>如果出现和局，所有投注红，绿，蓝七色波的金额将全数退回，会员也可投注和局</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>12.自选不中</span></li>
            <li>挑选5~11个号码为一个组合，当期号码 (所有正码与最后开出的特码) 皆没有坐落于投注时所挑选之号码组合内，则视为中奖，若是有任何一个当期号码开在所挑选的号码组合情形视为不中奖。</li>
            <li>例如当期号码为19、24、17、34、40、39 ,特别号49，所挑选5个号码，若所挑选的号码内皆没有坐落于当期号码，则为中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>13.连肖连尾</span> </li>
            <li class="redTxt">连肖</li>
            <li>生肖所对应的号码和特码生肖项目的一样；一个生肖对应多个号码，不论同生肖的号码出现一个或多个，派彩只派一次。每个生肖都有自己的赔率，下注组合的总赔率，取该组合生肖的最低赔率为总赔率。（请注意49亦算输赢）。</li>
            <li class="redTxt">二连肖</li>
            <li>以每二个生肖为一注，每注的每个生肖中如果都有当期开奖的所有7个号码中对应的生肖，则视为中奖。如果其中一个或多个生肖没有当期开奖的所有7个号码对应的生肖，则视为不中奖。例如生肖：蛇，马，是一注，开奖的 生肖是：马，牛，羊，鸡，狗，猪，鼠，那就是不中奖；再例如生肖：蛇，马，是一注，开奖的生肖是：蛇，马，牛，羊，鸡，狗，猪，那就是中奖。</li>
            <li class="redTxt">三连肖</li>
            <li>以每三个生肖为一注，每注的每个生肖中如果都有当期开奖的所有7个号码中对应的生肖，则视为中奖。如果其中一个或多个生肖没有当期开奖的所有7个号码对应的生肖，则视为不中奖。例如生肖：蛇，马，牛，是一注，开奖的生肖是：马，牛，羊，鸡，狗，猪，鼠，那就是不中奖；再例如生肖：蛇，马，牛，是一注，开奖的生肖是：蛇，马，牛，羊，鸡，狗，猪，那就是中奖。</li>
            <li class="redTxt">四连肖</li>
            <li>以每四个生肖为一注，每注的每个生肖中如果都有当期开奖的所有7个号码中对应的生肖，则视为中奖。如果其中一个或多个生肖没有当期开奖的所有7个号码对应的生肖，则视为不中奖。例如生肖：蛇，马，牛，羊，是一注，开奖的生肖是：马，牛，羊，鸡，狗，猪，鼠， 那就是不中奖；再例如生肖：蛇，马，牛，羊，是一注，开奖的生肖是：蛇，马，牛，羊，鸡，狗，猪，那就是中奖。</li>
            <li class="redTxt">五连肖</li>
            <li>以每五个生肖为一注，每注的每个生肖中如果都有当期开奖的所有7个号码中对应的生肖，则视为中奖。如果其中一个或多个生肖没有当期开奖的所有7个号 码对应的生肖，则视为不中奖。例如生肖：蛇，马，牛，羊，鸡，是一注，开奖的生肖是：马，牛，羊，鸡，狗，猪，那就是不中奖；再例如生肖：蛇，马，牛，羊，鸡，是一注，开奖的生肖是：蛇，马，牛，羊，鸡，狗，猪，那就是中奖。</li>
            <li class="redTxt">连尾</li>
            <li>尾数所对应的号码和尾数项目的一样；一个尾数对应多个号码，不论同尾数的号码出现一个或多个，派彩只派一次。每个尾数都有自己的赔率，下注组合的总赔率，取该组合尾数的最高赔率为总赔率。（请注意49亦算输赢）。</li>
            <li class="redTxt">二连尾</li>
            <li>以每二个尾数为一注，每注的每个尾数中如果都有当期开奖的所有7个号码中对应的尾数，则视为中奖。如果其中一个或多个尾数没有当期开奖的所有7个号码对应的尾数，则视为不中奖。例如：尾1、尾2，是一注，开奖的号码是：2、3、4、5、6、7、8，那就是不中奖；再例如：尾1、尾2，是一注，开奖的号码是：1、2、3、4、5、 6、7，那就是中奖。</li>
            <li class="redTxt">三连尾</li>
            <li>以每三个尾数为一注，每注的每个尾数中如果都有当期开奖的所有7个号码中对应的尾数，则视为中奖。如果其中一个或多个尾数没有当期开奖的所有7个号码对应的尾数，则视为不中奖。例如：尾1、尾2、尾3，是一注，开奖的号码是：2、3、4、5、6、7、8，那就是不中奖；再例如：尾1、尾2、尾3，是一注，开奖的号码是：1、2、3、4、5、6、7，那就是中奖。</li>
            <li class="redTxt">四连尾</li>
            <li>以每四个尾数为一注，每注的每个尾数中如果都有当期开奖的所有7个号码中对应的尾数，则视为中奖。如果其中一个或多个尾数没有当期开奖的所有7个号码对应的尾数，则视为不中奖。例如：尾1、尾2、尾3、尾4，是一注，开奖的号码是：2、3、4、5、6、7、8， 那就是不中奖；再例如：尾1、尾2、尾3、尾4，是一注，开奖的号码是：1、2、3、4、5、6、7，那就是中奖。</li>
            <li class="redTxt">五连尾</li>
            <li>以每五个尾数为一注，每注的每个尾数中如果都有当期开奖的所有7个号码中对应的尾数，则视为中奖。如果其中一个或多个尾数没有当期开奖的所有7个号码对应的尾数，则视为不中奖。例如：尾1、尾2、尾3、尾4、尾5，是一注，开奖的号码是：2、3、4、5、6、7、8， 那就是不中奖；再例如：尾1、尾2、尾3、尾4、尾5，是一注，开奖的号码是：1、2、3、4、5、6、7，那就是中奖。</li>
          </ul>
          <ul class="rule_ul">
            <li class="first redTxtTitle"><span>14.连码</span></li>
            <li class="redTxt">四全中</li>
            <li>选择投注号码每四个为一组（四个或四个以上），兑奖号为正码，如四个号码都在开奖号码的正码里面，视为中奖，其他情形都视为不中奖。</li>
            <li class="redTxt">三全中</li>
            <li>所投注的每三个号码为一组合，若三个号码都是开奖号码之正码，视为中奖，其余情形视为 不中奖。如06、07、08三个都是开奖号码之正码，视为中奖，如两个正码加上一个特别号 码视为不中奖 。</li>
            <li class="redTxt">三中二</li>
            <li>所投注的每三个号码为一组合，若其中2个是开奖号码中的正码，即为三中二，视为中奖；若3个都是开奖号码中的正码，即为三中二之中三，其余情形视为不中奖，如06、07、08 为一组合，开奖号码中有06、07两个正码，没有08，即为三中二，按三中二赔付；如开奖 号码中有06、07、08三个正码，即为三中二之中三，按中三赔付；如出现1个或没有，视为不中奖 。</li>
            <li class="redTxt">二全中</li>
            <li>所投注的每二个号码为一组合，二个号码都是开奖号码之正码，视为中奖，其余情形视为不 中奖（含一个正码加一个特别号码之情形）。</li>
            <li class="redTxt">二中特</li>
            <li>所投注的每二个号码为一组合，二个号码都是开奖号码之正码，叫二中特之中二；若其中一 个是正码，一个是特别号码，叫二中特之中特；其余情形视为不中奖 。</li>
            <li class="redTxt">特串</li>
            <li>所投注的每二个号码为一组合，其中一个是正码，一个是特别号码，视为中奖，其余情形视为不中奖（含二个号码都是正码之情形） 。</li>
          </ul>

        </div>
        <!-- 时时彩 -->
        <div class="rule" v-if="ruleTab=='ssc'">
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">1.第一球~第五球</span></li>
            <li><span class="redTxt">第一球~第五球：</span>第一球、第二球、第三球、第四球、第五球：指下注的每一球与开出之号码其开奖顺序及开奖号码相同，视为中奖，如第一球开出号码8，下注第一球为8者视为中奖，其余情形视为不中奖。<br>
              <span class="redTxt">单双大小：</span>根据相应单项投注第一球 ~ 第五球开出的球号，判断胜负。<br>
              <span class="redTxt">大小：</span>根据相应单项投注的第一球 ~ 第五球开出的球号大於或等於5为大，小於或等於4为小。<br>
              <span class="redTxt">单双：</span>根据相应单项投注的第一球 ~ 第五球开出的球号为双数叫双，如2、6；球号为单数叫单，如1、3。
            </li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">2.总和单双大小：</span></li>
            <li>
              <span class="redTxt">总和大小：</span>开出五个球的总和大於或等於23为总和大，小於或等於22为总和小。<br>
              <span class="redTxt">总和单双：</span>开出五个球的总和双数为总和双，单数为总和单。
            </li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">3.前三、中三、后三特殊玩法： 豹子 &gt; 顺子 &gt; 对子 &gt; 半顺 &gt; 杂六 。</span></li>
            <li>
              <span class="redTxt">豹子：</span>中奖号码的百位千位万位数字都相同。----如中奖号码为000、111、999等，中奖号码的百位千位万位数字相同，则投注豹子者视为中奖，其它视为不中奖。<br>
              <span class="redTxt">顺子：</span>中奖号码的百位千位万位数字都相连，不分顺序。（数字9、0、1相连）----如中奖号码为123、901、321、546等，中奖号码百位千位万位数字相连，则投注顺子者视为中奖，其它视为不中奖。<br>
              <span class="redTxt">对子：</span>中奖号码的百位千位万位任意两位数字相同。（不包括豹子）----如中奖号码为001，112、696，中奖号码有两位数字相同，则投注对子者视为中奖，其它视为不中奖。如果开奖号码为豹子,则对子视为不中奖。如中奖号码为001，112、696，中奖号码有两位数字相同，则投注对子者视为中奖，其它视为不中奖。<br>
              <span class="redTxt">半顺：</span>中奖号码的百位千位万位任意两位数字相连，不分顺序。（不包括顺子、对子。）----如中奖号码为125、540、390、706，中奖号码有两位数字相连，则投注半顺者视为中奖，其它视为不中奖。如果开奖号码为顺子、对子,则半顺视为不中奖。--如中奖号码为123、901、556、233，视为不中奖。<br>
              <span class="redTxt">杂六：</span>不包括豹子、对子、顺子、半顺的所有中奖号码。----如中奖号码为157，中奖号码位数之间无关联性，则投注杂六者视为中奖，其它视为不中奖。
              值是单数为总和单。
            </li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">4.龙虎和特殊玩法： 龙 &gt; 虎 &gt; 和 （0为最小，9为最大）。</span></li>
            <li>
              注：选择的位置1-2代表第一球（万位）和第二球（千位）两个位置开奖号码之间的比较，依次类推<br>
              <span class="redTxt">龙：</span>选择的位置上“首位”开奖号码大于“次位”开奖号码为龙，如出两个开奖号码相同为打和，退回投注本金。<br>
              <span class="redTxt">虎：</span>选择的位置上“首位”开奖号码小于“次位”开奖号码为虎，如出两个开奖号码相同为打和，退回投注本金。<br>
              <span class="redTxt">和：</span>选择的位置上“首位”开奖号码等于“次位”开奖号码为和，其它视为不中奖，扣除投注本金。
            </li>
          </ul>
          <ul class="rule_ul">
            <li class="first"><span class="bold redTxtTitle">4.龙虎和B特殊玩法： 龙 &gt; 虎 &gt; 和 （0为最小，9为最大）。</span></li>
            <li>
              注：选择的位置1-2代表第一球（万位）和第二球（千位）两个位置开奖号码之间的比较，依次类推<br>
              <span class="redTxt">龙：</span>选择的位置上“首位”开奖号码大于“次位”开奖号码为龙，如出两个开奖号码相同视为不中奖，扣除投注本金。<br>
              <span class="redTxt">虎：</span>选择的位置上“首位”开奖号码小于“次位”开奖号码为虎，如出两个开奖号码相同视为不中奖，扣除投注本金。<br>
              <span class="redTxt">和：</span>选择的位置上“首位”开奖号码等于“次位”开奖号码为和，其它视为不中奖，扣除投注本金。
            </li>
          </ul>
        </div>
      </div>

      <!-- ---- -->
    </div>

    <!-- ---- -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      ruleTab: "pl3",
      gameList: [],
      gameDes: {},
      roomeng: "",
    };
  },

  components: {},
  created() {
    this.getGameList();
  },
  computed: {
    ...mapState(["userInfo", "currentGame"]),
  },

  mounted() {},
  watch: {
    roomeng(val) {
      console.log("游戏名称", val);
    },
  },
  methods: {
    // 查询游戏列表
    getGameList() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        official: 0,
      };

      this.$http.post("getgamelist", obj).then((res) => {
        if (res.Status) {
          let obj = JSON.parse(res.Msg);

          let gameArr = obj.GameInfoS;

          this.gameList = gameArr;
          this.ruleTab = this.currentGame.Gametype;
          this.roomeng = this.currentGame.roomeng;
          this.getGameDes(this.currentGame.roomeng);
          console.log(gameArr, "游戏列表");
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    changeSel(e) {
      let val = e.target.value;

      this.roomeng = val;
      this.getGameDes(val);
      let item = this.gameList.find((item) => item.roomeng == val);
      this.ruleTab = item.Gametype;
    },
    // 获取游戏说明
    getGameDes(nameeng) {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        nameeng,
      };
      this.$http.post("getgamedescription", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          this.gameDes = resObj;
          // console.log(resObj, "游戏说明");
        } else {
          this.$message.warning(res.Msg);
        }
      });
    },
  },
};
</script>
<style  scoped>
.inside-os .rule-content .text .sscft-ol .number-table tr td {
  font-size: 13px;
}
.inside-os .rule-content .text .sscft-ol ul.inner-ul li {
  list-style-type: circle;
}
.inside-os .rule-content .text .sscft-ol ul {
  padding-left: 20px;
}
.inside-os .rule-content .text .sscft-ol ul li {
  list-style-type: disc;
  text-align: left;
  margin: 8px 0;
}
.inside-os .rule-content .text .sscft-ol .number-table tr td,
.inside-os .rule-content .text .sscft-ol .number-table tr th {
  border: 0.5px solid #6a6a6a;
  padding: 5px 15px;
}
.inside-os .rule-content .text .sscft-ol .number-table tr th {
  background-color: #af170f;
 
  color: #fff;
}
.inside-os .rule-content .text .sscft-ol .number-table {
  border: 1px solid #6a6a6a;
  border-spacing: 0;
  max-width: 800px;
  width: 100%;
  text-align: center;
}
.inside-os .rule-content .text .sscft-ol > li {
  list-style: decimal;
  margin: 8px 0;
}
.inside-os .rule-content .text .sscft-ol {
  padding-left: 20px;
}
.wxc_h3.mar10 {
  margin: 10px 0;
}
.wxc_h3 {
  text-indent: 43px;
  margin-bottom: 5px;
}
.wxcsuch_table {
  margin-left: 43px;
}
.wxcsuch_table td {
  text-align: center;
}
.inside-os .rule-content .text .section_noindex {
  padding-left: 40px;
  word-break: break-all;
}

.inside-os .rule-content .text .blacktxt {
  font-size: 12px;
  color: #000;
  line-height: 26px;
  padding-left: 40px;
}

.inside-os .rule-content .text .tab_style td {
  font-size: 14px;
  color: #2836f4;
  line-height: 26px;
}
.inside-os .rule-content .text .tab_style td,
.inside-os .rule-content .text .tab_style th {
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 5px;
  text-align: center;
}
.inside-os .rule-content .text .tab_style th {
  font-size: 14px;
  color: red;
  line-height: 26px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #f1f1f1;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.inside-os .rule-content .text .tab_style {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-collapse: collapse;
  width: 500px;
  margin: 10px 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.inside-os .rule-content .text .tab_container {
  padding-left: 40px;
}
.inside-os .rule-content .text .tab_container {
  padding: 5px;
  text-align: center;
  padding-left: 20px;
}
.inside-os .rule-content .h3 {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 0;
}
.inside-os .rule-content .text .redTxt {
  font-size: 12px;
  color: red;
  line-height: 26px;
}
.inside-os .rule-content .text .rule_ul li.first {
  padding-left: 0;
  font-size: 14px;
}

.inside-os .rule-content .text .rule_ul li {
  padding-left: 40px;
  font-size: 12px;
  line-height: 26px;
}
.inside-os .rule-content .text .redTxtTitle {
  font-size: 14px;
  color: red;
  line-height: 26px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.inside-os .rule-content .text .rule_ul {
  padding-left: 40px;
  list-style: none;
  margin: 0;
  margin-top: 20px;
}
.inside-os .rule-content .text {
  font-size: 12px;
}
.inside-os .rule-content .p {
  line-height: 24px;
  padding-left: 30px;
  margin-bottom: 8px;
}
.inside-os .rule-content .h3 {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 0;
}
.inside-os .com-title select {
  margin-left: 15px;
  padding: 1px 0 2px 3px;
}

.inside-os select {
  padding: 3px 0 3px 3px;
  border: 1px solid #ccc;
  color: #666;
  -webkit-box-shadow: 0 -1px 0 0 #ccc;
  box-shadow: 0 -1px 0 0 #ccc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.inside-os {
  /* width: 1018px;
  padding: 0 0 10px 10px;
  color: #4f4d4d; */
}
</style>